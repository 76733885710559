@import "../../../../Assets/styles/fileSass/variables";
@import "../../../../Assets/styles/fileSass/media";
@import "../../../../Assets/styles/fileSass/extend";
.vimeoIframe {
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 100%;
  max-height: 540px;
  margin-top: 30px;

  iframe {
    display: block;
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #000;
    height: 100%;
    max-height: calc(100vh - 250px);
  }
}
