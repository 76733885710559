@import "../../../../Assets/styles/fileSass/variables";
@import "../../../../Assets/styles/fileSass/media";
@import "../../../../Assets/styles/fileSass/extend";
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;

  &--innerPage {
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    img {
      height: 100%;
      max-height: 100px;
    }

    &-fixed {
      position: fixed;
    }
  }

  .ant-spin {
    line-height: 1;
  }
}

.loading-inherit {
  position: initial;
  transform: none;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;

  img {
    height: 100%;
    max-height: 100px;
  }
}