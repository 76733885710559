@import "../../../../../Assets/styles/fileSass/variables";
@import "../../../../../Assets/styles/fileSass/media";
@import "../../../../../Assets/styles/fileSass/extend";
.layoutPublicMenuFooter {
  &-main {
    padding-top: 90px;
    padding-bottom: 90px;
    @extend %displayBetween;

    @include media719 {
      flex-direction: column;
      align-items: center;
      padding: 40px 15px;
    }

    &-socialMedia {
      h5 {
        font-size: 18px;
        font-weight: 600;
        line-height: 2.28;
        text-align: center;
        margin-bottom: 17px;

        @include media719 {
          display: none;
        }
      }
      &__icon {
        @extend %displayCenter;

        & > div {
          margin-left: 29px;

          &:nth-child(1) {
            margin-left: 0;
          }
        }

        & .icon {
          opacity: 0.9;
          color: #686a78;
          cursor: pointer;
        }
      }
    }

    &-menu {
      @extend %displayColumn;
      @include media719 {
        margin-bottom: 30px;
      }

      &__item {
        font-weight: 600;
        line-height: 2.88;
        letter-spacing: 0.4px;
        cursor: pointer;
        @include media719 {
          text-align: center;
        }
      }
    }

    &-logo {
      @include media719 {
        margin-bottom: 17px;
      }
      .icon {
        width: 203px;
        height: 52px;
        margin-bottom: 18px;

        @include media1023 {
          width: 195px;
          height: 50px;
        }
      }

      &__company {
        color: #5d5d6e;
        display: block;
        @include media719 {
          text-align: center;
        }
      }
    }
  }
}
