@import "../../../../Assets/styles/fileSass/variables";
@import "../../../../Assets/styles/fileSass/media";
@import "../../../../Assets/styles/fileSass/extend";
.publicPageHeader {
  &-header {
    width: 100%;
    position: relative;

    &-img {
      width: 100%;

      @include media1200 {
        height: 400px;
      }

      @include media1023 {
        height: 250px;
      }

      @include media767 {
        height: 200px;
      }

      @include media479 {
        height: 180px;
      }
    }

    &-mask {
      width: 100%;
      position: relative;
      top: -30px;

      img {
        width: 100%;
        object-fit: contain;
      }

      @include media767 {
        top: -25px;
      }

      @include media479 {
        top: -15px;
      }
    }

    &__breadcrumb {
      @include media479 {
        width: 100%;
      }
    }
  }

  &-container {
    margin-top: 50px;
    padding: 0px 60px;
    text-align: center;

    &-title {
      font-size: 44px;
      font-weight: 600;
      color: #000;
      margin-bottom: 15px;

      @include media959 {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }

    &-desc {
      font-size: 20px;
      line-height: 30px;
      color: #686a78;

      @include media959 {
        font-size: 16px;
        line-height: 24px;
      }
    }

    @include media767 {
      text-align: left;
      padding: 0;
    }
  }

  &-data {
    margin-top: 100px;
    margin-bottom: 60px;
    display: flex;
    width: 100%;

    @include media1023 {
      &-imageRight {
        right: -10px;
      }
    }

    @include media959 {
      margin-top: 50px;
      margin-bottom: 0;
    }

    @include media767 {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &-image {
      display: flex;
      flex: 1;
      padding: 0 55px;
      position: relative;

      @include media1023 {
        padding: 0 15px;
      }

      @include media767 {
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0;
      }

      img {
        width: 100%;
        position: relative;
        z-index: 1;
        border-radius: 10px;
      }

      &-icon {
        position: absolute;
        left: 20px;
        bottom: -50px;

        @include media1023 {
          left: -10px;
          right: -10px;
        }

        @include media767 {
          display: none;
        }

        .icon {
          width: 150px;
          height: 150px;
        }
      }
    }

    &-detail {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: center;
      padding: 0 55px;

      @include media1023 {
        padding: 0 15px;
      }

      @include media767 {
        padding: 0;
      }

      &-title {
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        font-weight: 600;
        color: #000;

        @include media959 {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 0;
        }
      }

      &-desc {
        font-size: 20px;
        line-height: 27px;
        color: #686a78;

        @include media959 {
          font-size: 15px;
          line-height: 24px;
        }
      }
    }
  }
}