@import "../../../../Assets/styles/fileSass/variables";
@import "../../../../Assets/styles/fileSass/media";
@import "../../../../Assets/styles/fileSass/extend";
.privacyPolicy {
  &-inner {
      padding: 0 25%;
    &__title {
      text-align: center;
      font-size: 44px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    &__description {
      p {
        font-size: 20px;
        color: #686a78;
      }
    }
  }
}
