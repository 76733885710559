//center
%displayCenter {
  display: flex;
  align-items: center;
}

%displayJustCenter {
  display: flex;
  justify-content: center;
}

%displayCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

//column

%displayColumn {
  display: flex;
  flex-direction: column;
}

%displayColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%displayColumnBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%displayColumnJustCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// end

%displayEnd {
  display: flex;
  justify-content: flex-end;
}

%displayEndCenter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// between

%displayBetween {
  display: flex;
  justify-content: space-between;
}

%displayBetweenCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

//wrap

%displayWrap {
  display: flex;
  flex-wrap: wrap;
}

%displayWrapBetween {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

//full
%displayFull {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

// images
%imgCoverCenter {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}

%imgContainCenter {
  background-position: center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  -o-background-size: contain;
}

%backgroundAuto {
  -webkit-background-size: auto;
  -moz-background-size: auto;
  background-size: auto;
  -o-background-size: auto;
}

// general

%selectClose {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently*/
}

%slow300 {
  transition: all 300ms linear;
}

////////////////////Butonlar//////////////////////////

//////////////////////// scrolll /////////////////////

%scrollTransparent {
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-primary;
  }
}

%scrollYBlue {
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f7f6f6;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-primary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-primary;
  }
}

%scrollXBlue {
  overflow-x: auto !important;

  &::-webkit-scrollbar {
    height: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-primary;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-primary;
  }
}

%scrollYyellow {
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background-image: linear-gradient(to top, #fff, #f0f5fb 50%, #fff);
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-yellow;
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-yellow;
  }
}

%scrollYRed {
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;

    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scroll-red;
    border-radius: 3.5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scroll-red;
  }
}