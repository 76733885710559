.courseCard-container-information-date,
.formMultiUploadFile-file-container,
.formMultiUploadFile-file,
.formMultiUploadFile-container,
.formRadioGroup__radioGroup .ant-radio-wrapper,
.formDatePicker .ant-picker,
.formCheckBox .ant-checkbox-wrapper,
.formSelectBox__select .ant-select-selection-item,
.breadCrumb-router,
.breadCrumb,
.headerCoursesLike,
.headerCourses-information-right-progress-container .ant-rate,
.ant-form-item-explain,
.ant-picker-ranges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.modalGeneral-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.courseCard-image__icon,
.courseCard-image--close,
.formMultiUploadFile-container-btn,
.formResponsive-fullWidthCenter,
.formResponsive-btn,
.formSubmitButton,
.headerCoursesLike-like,
.tableCustom .ant-pagination-item-link,
.tableCustom .ant-pagination-item,
.ant-angularRed,
.ant-angularGreen,
.ant-angularBlue,
.ant-whiteBtn,
.ant-yellowBtn,
.ant-blueBtn,
.ant-redBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.courseCard-container-information-rate,
.formMultiUploadFile,
.formResponsive-fullWidthColumn,
.headerCourses-information-right,
.modalGeneral-whiteModal-container,
.confirmModal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.courseCard-container-information,
.courseCard-container-header,
.formDateAndTimePicker-container,
.headerCourses-information-right-progress-header,
.headerCourses-information {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.formListInputs-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.formResponsive-triple,
.formResponsive-startAndEndTime,
.formResponsive-double,
.formResponsive--modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.formTextArea__textarea,
.modalGeneral-whiteModal-container,
.ant-picker-time-panel-column,
.ant-select-dropdown .rc-virtual-list-holder {
  overflow-y: auto !important;
}

.formTextArea__textarea::-webkit-scrollbar,
.modalGeneral-whiteModal-container::-webkit-scrollbar,
.ant-picker-time-panel-column::-webkit-scrollbar,
.ant-select-dropdown .rc-virtual-list-holder::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

.formTextArea__textarea::-webkit-scrollbar-track,
.modalGeneral-whiteModal-container::-webkit-scrollbar-track,
.ant-picker-time-panel-column::-webkit-scrollbar-track,
.ant-select-dropdown .rc-virtual-list-holder::-webkit-scrollbar-track {
  background: #f7f6f6;
  border-radius: 5px;
}

.formTextArea__textarea::-webkit-scrollbar-thumb,
.modalGeneral-whiteModal-container::-webkit-scrollbar-thumb,
.ant-picker-time-panel-column::-webkit-scrollbar-thumb,
.ant-select-dropdown .rc-virtual-list-holder::-webkit-scrollbar-thumb {
  background: #13385a;
  border-radius: 5px;
}

.formTextArea__textarea::-webkit-scrollbar-thumb:hover,
.modalGeneral-whiteModal-container::-webkit-scrollbar-thumb:hover,
.ant-picker-time-panel-column::-webkit-scrollbar-thumb:hover,
.ant-select-dropdown .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover {
  background: #13385a;
}

.tableCustom .ant-table-content {
  overflow-x: auto !important;
}

.tableCustom .ant-table-content::-webkit-scrollbar {
  height: 5px;
  border-radius: 5px;
}

.tableCustom .ant-table-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.tableCustom .ant-table-content::-webkit-scrollbar-thumb {
  background: #13385a;
  border-radius: 5px;
}

.tableCustom .ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #13385a;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
input,
tr {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1;
  font-family: "Source Sans Pro";
  font-size: 16px;
  color: #000;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (max-width: 1365px) {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  font,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  input,
  tr {
    font-family: "Poppins", sans-serif;
  }

  html,
  body {
    background-color: white;
  }
}

html,
body {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

a:hover {
  color: #00aad2;
}

#root {
  min-height: 100vh;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-form {
  width: 100%;
}

.ant-popover-inner {
  border-radius: 10px;
}

.ant-popover-inner-content {
  padding: 0px;
}

.ant-select-dropdown {
  padding: 10px 5px 10px 5px !important;
  z-index: 99999;
  border-radius: 6px;
  -webkit-box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 0;
}

.ant-select-dropdown .rc-virtual-list-scrollbar {
  display: none;
}

.ant-select-item-option {
  padding: 4px 14px !important;
  min-height: 29px !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ant-select-item-option-content {
  display: block;
  height: 100%;
  line-height: 1.44;
  font-weight: 500;
  font-size: 16px;
  font-weight: normal !important;
  color: #101426;
  white-space: normal;
}

.ant-picker-time-panel-column {
  width: 64px;
}

.ant-picker-now {
  border: 1px solid #dae1ed;
  height: 24px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 5px;
  border-radius: 2px;
}

.ant-picker-now:hover {
  border: solid 1px #596b75;
}

.ant-picker-now-btn {
  font-size: 14px;
}

.ant-picker-now-btn:hover {
  color: #000;
}

.ant-picker-ok {
  width: 100%;
}

.ant-picker-ok button {
  width: 100%;
  font-size: 15px;
  border: solid 1px #dae1ed;
  color: #101426;
  background-color: #ffffff;
}

.ant-picker-ok button:focus {
  border: solid 1px #596b75;
  background-color: transparent;
}

.ant-picker-ok button:hover {
  border: solid 1px #596b75;
  color: #101426;
  background-color: transparent;
}

.ant-picker-ok button span {
  text-transform: capitalize;
  font-size: 14px;
}

.ant-picker-today-btn {
  color: #101426;
}

.ant-picker-today-btn:hover {
  color: #101426;
}

.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #68afff !important;
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #68afff !important;
}

.ant-picker-dropdown {
  z-index: 99999 !important;
}

.ant-dropdown {
  z-index: 99999;
}

.ant-dropdown ul {
  border-radius: 4px;
}

.ant-dropdown-menu {
  padding: 0;
}

.formButtonIcon {
  display: inline-block;
  margin-left: 10px;
}

.ant-angularRed,
.ant-angularGreen,
.ant-angularBlue,
.ant-whiteBtn,
.ant-yellowBtn,
.ant-blueBtn,
.ant-redBtn {
  width: 100%;
  min-height: 48px;
  border-radius: 35px;
  padding: 0 17px;
  border: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 10px;
}

[disabled].ant-angularRed,
[disabled].ant-angularGreen,
[disabled].ant-angularBlue,
[disabled].ant-whiteBtn,
[disabled].ant-yellowBtn,
[disabled].ant-blueBtn,
[disabled].ant-redBtn {
  border-color: transparent !important;
}

[disabled].ant-angularRed span,
[disabled].ant-angularGreen span,
[disabled].ant-angularBlue span,
[disabled].ant-whiteBtn span,
[disabled].ant-yellowBtn span,
[disabled].ant-blueBtn span,
[disabled].ant-redBtn span {
  color: #fff;
}

.ant-angularRed span,
.ant-angularGreen span,
.ant-angularBlue span,
.ant-whiteBtn span,
.ant-yellowBtn span,
.ant-blueBtn span,
.ant-redBtn span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
}

.ant-angularRed .ant-form-item,
.ant-angularGreen .ant-form-item,
.ant-angularBlue .ant-form-item,
.ant-whiteBtn .ant-form-item,
.ant-yellowBtn .ant-form-item,
.ant-blueBtn .ant-form-item,
.ant-redBtn .ant-form-item {
  width: 100%;
}

.ant-redBtn {
  background-color: #920e0e;
}

.ant-redBtn span {
  color: #fff;
}

.ant-redBtn:hover,
.ant-redBtn:focus {
  background-color: transparent;
  border: 1px solid #920e0e;
}

.ant-redBtn:hover span,
.ant-redBtn:focus span {
  color: #920e0e !important;
}

.ant-redBtn:hover .icon,
.ant-redBtn:focus .icon {
  color: #920e0e;
}

.ant-blueBtn {
  background-color: #2c51fe;
}

.ant-blueBtn span {
  color: #fff;
}

.ant-blueBtn:hover,
.ant-blueBtn:focus {
  background-color: transparent;
  border: 1px solid #2c51fe;
}

.ant-blueBtn:hover span,
.ant-blueBtn:focus span {
  color: #2c51fe !important;
}

.ant-blueBtn:hover .icon,
.ant-blueBtn:focus .icon {
  color: #2c51fe;
}

.ant-yellowBtn {
  background-color: #fdd753;
}

.ant-yellowBtn span {
  color: #000;
}

.ant-yellowBtn:hover,
.ant-yellowBtn:focus {
  background-color: transparent;
  border: 1px solid #fdd753;
}

.ant-yellowBtn:hover span,
.ant-yellowBtn:focus span {
  color: #fdd753 !important;
}

.ant-yellowBtn:hover .icon,
.ant-yellowBtn:focus .icon {
  color: #fdd753;
}

.ant-whiteBtn {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 13px -10px rgba(0, 0, 0, 0.51);
  box-shadow: 0 2px 13px -10px rgba(0, 0, 0, 0.51);
  border: solid 1px #fcfcfc;
  color: #fff;
}

.ant-whiteBtn span {
  color: #fff;
  font-weight: 600;
  letter-spacing: normal;
}

.ant-whiteBtn:hover,
.ant-whiteBtn:focus {
  background-color: #fff;
  border: solid 1px #fcfcfc;
  -webkit-box-shadow: 0 2px 13px -10px black;
  box-shadow: 0 2px 13px -10px black;
}

.ant-angularBlue {
  background-color: #04264d;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.ant-angularBlue span {
  color: #fff;
}

.ant-angularBlue:hover,
.ant-angularBlue:focus {
  background-color: #04264d;
}

.ant-angularBlue:hover .icon,
.ant-angularBlue:focus .icon {
  color: #04264d;
}

.ant-angularGreen {
  background-color: #66bb6a;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.ant-angularGreen span {
  color: #fff;
}

.ant-angularGreen:hover,
.ant-angularGreen:focus {
  background-color: #66bb6a;
}

.ant-angularRed {
  background-color: #ff0000;
  border-radius: 8px;
}

.ant-angularRed span {
  color: #fff;
}

.ant-angularRed:hover,
.ant-angularRed:focus {
  background-color: #ff0000;
}

.ant-angularRed:hover .icon,
.ant-angularRed:focus .icon {
  color: #fff;
}

.ant-form-item-explain div {
  color: #ff4d4f;
  font-size: 15.5px;
}

.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.ant-notification {
  z-index: 99999;
}

.ant-rate-star-full * {
  color: #fadb14 !important;
}

.ant-rate-star-half .ant-rate-star-first * {
  color: #fadb14 !important;
}

.containerHeightCenter {
  min-height: calc(100vh - 113px);
  padding: 1.875rem 1.25rem;
  margin: auto;
  max-width: 1144px;
  width: 100%;
}

@media (max-width: 1365px) {
  .containerHeightCenter {
    padding: 30px 20px !important;
  }
}

@media (max-width: 767px) {
  .containerHeightCenter {
    min-height: calc(100vh - 86px) !important;
    padding: 30px 15px !important;
  }
}

.containerCenter {
  padding: 1.875rem 1.25rem;
  margin: auto;
  max-width: 1144px;
  width: 100%;
}

@media (max-width: 1365px) {
  .containerCenter {
    padding: 30px 20px !important;
  }
}

@media (max-width: 767px) {
  .containerCenter {
    padding: 30px 25px !important;
  }
}

.containerBottom {
  min-height: calc(100vh - 113px);
  padding: 30px;
  max-width: 1240px;
  width: 100%;
  margin: auto;
}

@media (max-width: 1600px) {
  .containerBottom {
    padding: 30px 20px;
    max-width: 1200px;
  }
}

@media (max-width: 1023px) {
  .containerBottom {
    padding: 30px 15px !important;
  }
}

@media (max-width: 767px) {
  .containerBottom {
    padding: 30px 25px !important;
    margin-top: 30px;
  }
}

.containerMini {
  padding: 29px 36px 35px;
  border-radius: 18px;
  background-color: #fdfefe;
}

@media (max-width: 1365px) {
  .containerMini {
    padding: 23px 30px 29px;
  }
}

@media (max-width: 1023px) {
  .containerMini {
    padding: 18px 25px 24px;
  }
}

@media (max-width: 767px) {
  .containerMini {
    padding: 15px 22px 21px;
  }
}

.containerSmall {
  padding: 30px;
  margin: auto;
  max-width: 1240px;
  width: 100%;
}

@media (max-width: 1600px) {
  .containerSmall {
    padding: 20px;
    max-width: 1200px !important;
  }
}

@media (max-width: 1023px) {
  .containerSmall {
    padding: 15px 10px !important;
  }
}

.title {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .title {
    font-size: 18px;
  }
}

.confirmModal .ant-modal-body {
  padding: 24px 24px 20px;
}

@media (max-width: 1023px) {
  .confirmModal .ant-modal-body {
    padding: 20px 20px 16px;
  }
}

.confirmModal .ant-modal-confirm-title {
  margin-bottom: 15px;
  font-weight: 600;
}

.confirmModal .ant-btn {
  border-radius: 4px;
  border: none;
  background: #4caf50;
}

.confirmModal .ant-btn:hover {
  background: #338d36;
}

.confirmModal .ant-btn span {
  color: #fff;
}

.confirmModal--success .ant-modal-confirm-title {
  color: #4caf50;
}

.confirmModal--warning .ant-modal-confirm-title {
  color: #faad14;
}

.confirmModal--error .ant-modal-confirm-title {
  color: #ff4d4f;
}

.confirmModal--info .ant-modal-confirm-title {
  color: #1890ff;
}

.confirmModal--confirm .ant-modal-confirm-title {
  color: #000;
}

.confirmModal--confirm .ant-btn:nth-child(1) {
  background: #dd3c30;
}

.confirmModal--confirm .ant-btn:nth-child(1):hover {
  background: #c42e24;
}

.icon {
  width: 24px;
  height: 24px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  color: #000;
}

.modalGeneral {
  padding: 0;
}

.modalGeneral .ant-modal-content .ant-modal-close,
.modalGeneral .ant-modal-content .ant-modal-footer {
  display: none;
}

.modalGeneral-title {
  margin-left: -20px;
}

.modalGeneral-title span {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1365px) {
  .modalGeneral-title span {
    font-size: 19px;
  }
}

@media (max-width: 767px) {
  .modalGeneral-title span {
    font-size: 16px;
    font-weight: 500;
  }
}

.modalGeneral-title--right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 20px;
}

.modalGeneral-title--left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: 0;
}

.modalGeneral-close {
  position: absolute;
  z-index: 10;
  top: 19px;
  right: 20px;
}

.modalGeneral-close .icon {
  cursor: pointer;
  color: #000;
  width: 24px;
}

.modalGeneral-close--left {
  left: 20px;
}

.modalGeneral--backgroundClose {
  width: 100% !important;
}

.modalGeneral--backgroundClose .ant-modal-body,
.modalGeneral--backgroundClose .ant-modal-content {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 100%;
  padding: 0;
}

.modalGeneral--closeBtnActive .modalGeneral-whiteModal-container {
  margin-top: 20px;
}

.modalGeneral-whiteModal {
  overflow: hidden;
  border-radius: 6px;
  -webkit-box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 12px 38px -1px rgba(0, 0, 0, 0.15);
  width: 900px !important;
  max-width: 95vw;
  max-height: 90vh;
}

.modalGeneral-whiteModal .ant-modal-body {
  padding: 26px 0 25px 20px;
}

.modalGeneral-whiteModal-container {
  overflow-x: hidden;
  padding: 0 10px 10px 0;
  margin-right: 5px;
  position: relative;
  max-height: 70vh;
  min-height: 140px;
}

.modalGeneral--smallWidth {
  width: 400px !important;
}

.modalGeneral--smallWidth .formSubmitButton .ant-row {
  margin-bottom: 0;
}

.tableCustom {
  margin: 20px 0;
  -webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  position: relative;
}

.tableCustom .ant-pagination {
  margin-right: 20px;
}

.headerCourses-information-left .headerCourses-information-left__descripton *,
.headerCourses-information-left__descripton .headerCourses-information-left *,
.headerCourses-information-left .headerCourses-information-left__descripton {
  font-size: 18px;
  line-height: 1.56;
  color: #686a78;
  line-break: auto;
  text-align: justify;
}

@media (max-width: 1365px) {
  .headerCourses-information-left .headerCourses-information-left__descripton *,
  .headerCourses-information-left__descripton .headerCourses-information-left *,
  .headerCourses-information-left .headerCourses-information-left__descripton {
    font-size: 16px;
  }
}

@media (max-width: 1023px) {
  .headerCourses-information-left .headerCourses-information-left__descripton *,
  .headerCourses-information-left__descripton .headerCourses-information-left *,
  .headerCourses-information-left .headerCourses-information-left__descripton {
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .headerCourses-information-left .headerCourses-information-left__descripton *,
  .headerCourses-information-left__descripton .headerCourses-information-left *,
  .headerCourses-information-left .headerCourses-information-left__descripton {
    font-size: 14px;
  }
}

.headerCoursesLike {
  display: flex;
}

.breadCrumb {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 7px;
  margin-left: 5px;
}

.breadCrumb-router {
  margin: 0 3px 5px 0;
}

.breadCrumb-router span {
  font-size: 18px;
  color: #686a78;
  margin-right: 3px;
}

@media (max-width: 1365px) {
  .breadCrumb-router span {
    font-size: 17px;
  }
}

@media (max-width: 1023px) {
  .breadCrumb-router span {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .breadCrumb-router span {
    font-size: 14px;
  }
}

.breadCrumb-router .icon {
  color: #686a78;
}

.formElementLabel {
  font-size: 12px;
  color: #8f9bb3;
  font-weight: 600;
  line-height: 1.69;
  display: block;
}

.formElementLabel.required:after {
  content: " *";
  color: #ff4d4f;
}

.formTextInput {
  width: 100%;
}

.formTextInput-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.formTextInput-inner .ant-form-item {
  width: 100%;
}

.formTextInput__input {
  border: solid 1px #dae1ed;
  background-color: #fff !important;
  color: #101426;
  line-height: 44px;
  font-size: 16px;
  height: 44px;
  margin-top: 6px;
  border-radius: 4px;
  padding: 0 12px;
}

.formTextInput__input:focus {
  border: solid 1px #596b75 !important;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
}

.formTextInput__input:hover {
  border: solid 1px #596b75 !important;
}

.formTextInput--password input {
  color: #101426;
  line-height: 1.44;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff !important;
}

.formTextInput--password .formTextInput__input {
  padding-top: 0;
}

.formTextInput--password .ant-input-suffix svg {
  font-size: 20px;
}

.formTextInput .ant-input-password {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.formSelectBox {
  width: 100%;
}

.formSelectBox .ant-form-item-control-input {
  min-height: 0;
}

.formSelectBox .ant-select-selection-placeholder {
  line-height: 44px !important;
}

.formSelectBox__select {
  margin-top: 6px;
  border-radius: 4px;
  background-color: #fff !important;
  color: #101426;
  line-height: 1.44;
  font-weight: 500;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  font-size: 16px;
}

.formSelectBox__select.ant-select-focused .ant-select-selector {
  border: solid 1px #596b75 !important;
}

.formSelectBox__select:hover .ant-select-selector {
  border: solid 1px #596b75 !important;
}

.formSelectBox__select .ant-select-arrow {
  right: 12px;
  top: 22px;
}

.formSelectBox__select .ant-select-arrow svg {
  font-size: 14px;
}

.formSelectBox__select .ant-select-selection-search-input {
  height: 100% !important;
  font-size: 16px;
  line-height: 44px;
}

.formSelectBox__select .ant-select-selection-placeholder {
  font-size: 16px;
  display: block;
  padding-left: 12px !important;
}

.formSelectBox__select .ant-select-selection-item {
  padding: 4px 12px !important;
  font-weight: normal;
  color: #101426;
  font-size: 16px;
}

.formSelectBox__select .ant-select-selector {
  padding: 0 20px 0 0 !important;
  border-radius: 4px !important;
  border: solid 1px #dae1ed !important;
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #fff !important;
}

.formSelectBox__select.ant-select-disabled .ant-select-selector {
  background: #f7f6f6 !important;
}

.formSelectBox__select.ant-select-disabled:hover .ant-select-selector {
  background: #fff !important;
}

.formSelectBox__select.ant-select-disabled .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
}

.formSelectBox .ant-select-selector {
  min-height: 44px;
}

.formSelectBox .formTextInput {
  width: 100% !important;
}

.formSelectBox-multiple .ant-select-selection-placeholder {
  padding-left: 0 !important;
}

.formSelectBox-multiple .ant-select-selection-item {
  margin: 4px;
  border: 1px solid #dae1ed;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 93%;
}

.formSelectBox-multiple .ant-select-selection-item-content {
  color: #101426;
  letter-spacing: 0.3px;
  font-size: 13px;
}

.formSelectBox-multiple svg {
  font-size: 11px;
}

.formSelectBox .ant-select-clear {
  width: 15px;
  height: 15px;
  z-index: 100;
}

.formSelectBox .ant-select-clear svg {
  font-size: 13px;
}

.formSelectBox .ant-row:nth-child(3) {
  margin-bottom: 0;
}

.formSubmitButton {
  width: 100%;
  position: relative;
}

.formSubmitButton-left {
  margin-right: 12px;
}

.formSubmitButton-right {
  margin-left: 12px;
}

.formSubmitButton .icon {
  color: #fff;
  width: 22px;
  height: 22px;
}

.formSubmitButton .ant-form-item {
  margin-bottom: 0;
}

.formSubmitButton--fullWidth .ant-row {
  width: 100%;
}

.formInputNumber {
  width: 100%;
}

.formInputNumber .ant-input-number-focused {
  border: solid 1px #596b75;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08) !important;
}

.formInputNumber__input {
  width: 100%;
  margin-top: 6px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
}

.formInputNumber__input input {
  background-color: #fff;
  height: 44px;
  color: #101426;
  line-height: 44px;
  font-size: 16px;
  outline: none !important;
}

.formInputNumber__input:hover {
  border: solid 1px #596b75;
}

.formInputNumber .ant-input-number-disabled {
  background-color: #f5f5f5;
}

.formInputNumber .ant-input-number-handler-wrap {
  display: none;
  width: 0;
}

.formTextArea {
  width: 100%;
}

.formTextArea__textarea {
  resize: none;
  height: 90px !important;
  margin-top: 6px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
  background-color: #fff !important;
  padding: 8px 12px;
  color: #101426;
  line-height: 1.44;
  font-size: 16px;
}

.formTextArea__textarea:focus {
  border: solid 1px #596b75;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
}

.formTextArea__textarea:hover {
  border: solid 1px #596b75;
}

.formCheckBox__label {
  font-weight: 400;
  font-size: 16px;
  color: #101426;
  line-height: 1.64;
}

.formCheckBox__label.required:after {
  content: " *";
  color: #ff4d4f;
}

.formCheckBox .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
  z-index: 1;
}

.formCheckBox .ant-checkbox-wrapper .ant-checkbox-inner:hover,
.formCheckBox .ant-checkbox-wrapper .ant-checkbox-inner:focus {
  border: solid 1px #596b75 !important;
}

.formCheckBox .ant-checkbox-wrapper .ant-checkbox::after {
  border: none !important;
}

.formCheckBox .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #68afff;
}

.formCheckBox
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner::after {
  left: 25%;
}

.formCheckBox-contractActive {
  text-decoration: underline;
}

.formTimePicker {
  width: 128px;
}

.formTimePicker .ant-picker-focused,
.formTimePicker .ant-picker:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.formTimePicker__datePicker {
  margin-top: 6px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
  background-color: #fff;
  padding: 0 12px;
  color: #101426;
  line-height: 44px;
  font-size: 16px;
}

.formTimePicker__datePicker:focus {
  border: solid 1px #596b75;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
}

.formTimePicker__datePicker:hover {
  border: solid 1px #596b75;
}

.formTimePicker__datePicker input {
  color: #101426;
  line-height: 1.44;
  font-weight: 400;
  font-size: 16px;
}

.formDatePicker {
  width: 280px;
  min-width: 140px;
}

.formDatePicker .ant-picker-focused,
.formDatePicker .ant-picker:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.formDatePicker__datePicker {
  display: block;
  height: 44px;
  margin-top: 6px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
  background-color: #fff !important;
  padding: 4px 12px !important;
}

.formDatePicker__datePicker:hover {
  border: solid 1px #596b75;
}

.formDatePicker__datePicker .anticon {
  font-size: 22px;
}

.formDatePicker__datePicker input {
  color: #868891;
  line-height: 1.57;
  font-size: 16px;
  height: 24px;
  line-height: 44px;
}

.formDatePicker .ant-picker-suffix svg,
.formDatePicker .ant-picker-clear svg {
  font-size: 15px;
}

.formDateAndTimePicker .ant-picker {
  width: 100% !important;
}

.formDateAndTimePicker-container .formDatePicker {
  width: calc(100% - 148px);
  max-width: 100%;
}

@media (max-width: 767px) {
  .formDateAndTimePicker-container .formDatePicker {
    width: calc(50% - 10px);
  }
}

.formDateAndTimePicker-container .formDatePicker-container__datePicker {
  width: 100%;
}

.formDateAndTimePicker-container .formTimePicker {
  margin-left: 20px;
  min-width: 128px;
}

@media (max-width: 767px) {
  .formDateAndTimePicker-container .formTimePicker {
    min-width: calc(50% - 10px);
    width: calc(50% - 10px);
  }
}

.formRadioGroup {
  width: 100%;
}

.formRadioGroup__radioGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.formRadioGroup__radioGroup .ant-radio-wrapper {
  line-height: 1.44;
  font-weight: 500;
  font-size: 16px;
  white-space: normal;
}

.formRadioGroup__radioGroup .ant-radio-wrapper span {
  color: #101426;
}

.formRadioGroup__radioGroup .ant-radio-wrapper .ant-radio {
  height: 18px;
}

.formRadioGroup__radioGroup
  .ant-radio-wrapper
  .ant-radio:hover
  .ant-radio-inner {
  border-color: #68afff;
}

.formRadioGroup__radioGroup .ant-radio-wrapper .ant-radio-inner:after {
  background-color: #68afff;
}

.formRadioGroup__radioGroup
  .ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #68afff;
}

.formRadioGroup .formTextInput-container__label {
  display: none;
}

.formSwitch .ant-switch-checked {
  background-color: #68afff;
}

.formMask {
  width: 100%;
}

.formMask__input {
  margin-top: 6px;
  height: 44px;
  border-radius: 4px;
  border: solid 1px #dae1ed;
  background-color: #fff;
  padding: 0 12px;
  color: #101426;
  line-height: 44px;
  font-size: 16px;
  outline: none;
  width: 100%;
}

.formMask__input[disabled] {
  cursor: not-allowed;
}

.formMask__input:focus {
  border: solid 1px #596b75;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
}

.formMask__input:hover {
  border: solid 1px #596b75;
}

.formMask .ant-form-item-has-error input {
  border-color: #ff4d4f;
  outline: none;
}

.formMask .ant-form-item-has-error input:focus {
  border: solid 1px #ff4d4f;
}

.formMask .ant-form-item-has-error input:hover {
  border: solid 1px #ff4d4f;
}

.formListInputs {
  width: 100%;
}

.formListInputs--label {
  font-size: 16px;
  margin-bottom: 15px !important;
}

.formListInputs-list > div:last-child {
  margin-top: 6px;
  margin-bottom: 24px;
  height: 32px;
}

.formListInputs-list > div:last-child .ant-form-item-control-input,
.formListInputs-list > div:last-child .ant-form-item-control-input-content,
.formListInputs-list > div:last-child button {
  border-radius: 4px;
}

.formListInputs-list > div:last-child button {
  padding-top: 6px;
  color: #101426;
}

.formListInputs-list > div:last-child button span {
  font-size: 16px;
}

.formListInputs-list > div:last-child button:hover,
.formListInputs-list > div:last-child button:focus {
  color: #101426;
  border-color: #000;
}

.formListInputs-list .formTextInput,
.formListInputs-list .formInputNumber,
.formListInputs-list .formDatePicker {
  margin-right: 10px;
}

.formListInputs-list-item-close {
  height: 22px;
}

.formListInputs-list-item-close .icon {
  cursor: pointer;
  color: #ff4d4f;
  width: 35px;
}

.formResponsive-double .formTextInput,
.formResponsive--modal .formTextInput,
.formResponsive-double .formSelectBox,
.formResponsive--modal .formSelectBox,
.formResponsive-double .formInputNumber,
.formResponsive--modal .formInputNumber,
.formResponsive-double .formSwitch,
.formResponsive--modal .formSwitch,
.formResponsive-double .formCheckBox,
.formResponsive--modal .formCheckBox,
.formResponsive-double .formTimePicker,
.formResponsive--modal .formTimePicker,
.formResponsive-double .formDatePicker,
.formResponsive--modal .formDatePicker,
.formResponsive-double .formDateAndTimePicker,
.formResponsive--modal .formDateAndTimePicker,
.formResponsive-double .formRadioGroup,
.formResponsive--modal .formRadioGroup,
.formResponsive-double .formResponsive-startAndEndTime,
.formResponsive--modal .formResponsive-startAndEndTime,
.formResponsive-double > .formMask,
.formResponsive--modal > .formMask {
  width: 48%;
}

.formResponsive-btn {
  width: 100%;
}

.formResponsive-btn .formSubmitButton {
  width: auto;
  margin: 0 10px;
}

.formResponsive-fullWidth {
  width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.formResponsive-fullWidthColumn {
  width: 100% !important;
}

.formResponsive-fullWidthCenter {
  width: 100% !important;
}

.formResponsive-startAndEndTime .formTimePicker__datePicker {
  width: 100%;
}

.formResponsive-triple .formTextInput,
.formResponsive-triple .formSelectBox,
.formResponsive-triple .formInputNumber,
.formResponsive-triple .formSwitch,
.formResponsive-triple .formCheckBox,
.formResponsive-triple .formTimePicker,
.formResponsive-triple .formDatePicker,
.formResponsive-triple .formDateAndTimePicker,
.formResponsive-triple .formRadioGroup,
.formResponsive-triple .formResponsive-startAndEndTime,
.formResponsive-triple > .formMask {
  width: 31%;
}

.formResponsive-failed {
  text-align: center;
  color: #ff4d4f;
  margin: 10px 0;
}

.formMultiUploadFile {
  width: 100%;
}

.formMultiUploadFile-container {
  margin-bottom: 12px;
  margin-top: 6px;
  height: 44px;
  border-radius: 4px;
  width: 150px;
  border: solid 1px #dae1ed;
  padding: 3px;
  background-color: #fff;
  color: #101426;
  line-height: 1.44;
  font-weight: 500;
  font-size: 16px;
}

.formMultiUploadFile-container:focus {
  border: solid 1px #596b75;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
}

.formMultiUploadFile-container:hover {
  border: solid 1px #596b75;
}

.formMultiUploadFile-container-btn {
  cursor: pointer;
  position: relative;
  color: #596b75;
  background-color: #fff;
  border: none;
  height: 42px;
  overflow: hidden;
  width: 150px;
}

.formMultiUploadFile-container-btn__name {
  font-size: 16px;
  color: #101426;
  pointer-events: none;
}

.formMultiUploadFile-container-btn .anticon {
  margin-right: 10px;
  color: #000;
}

.formMultiUploadFile-container-btn input[type="file"] {
  cursor: pointer;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 150px;
}

.formMultiUploadFile-file {
  max-width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 0 14px;
  cursor: pointer;
  border: 1px solid #dae1ed;
  background-color: #fff;
}

.formMultiUploadFile-file-close {
  cursor: pointer;
  height: 24px;
  margin-right: -5px;
}

.formMultiUploadFile-file-close .icon {
  width: 22px;
}

.formMultiUploadFile-file-container {
  overflow: hidden;
  max-width: 100%;
  background-color: #fff;
  width: 100%;
  height: 44px;
  cursor: pointer;
}

.formMultiUploadFile-file-container span {
  font-size: 16px;
}

.defaultTableDiv {
  width: 100%;
  margin-bottom: 40px;
}

.defaultTableDiv-row {
  display: flex;
  background-color: #fff !important;
  border-radius: 10px;
  margin-bottom: 7px;
  padding: 20px;
}

.defaultTableDiv--center {
  justify-content: center;
}

.defaultTableDiv-row-bg-transparent {
  display: flex;
  background-color: transparent !important;
  border-radius: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  padding: 20px;
}

.defaultTableDiv-row-column {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  display: flex;
  align-items: center;
}

.defaultTableDiv--col0_5 {
  width: 4.1666666665%;
}

.defaultTableDiv--col1 {
  width: 8.3333333333%;
}

.defaultTableDiv--col1_5 {
  width: 12.49%;
}

.defaultTableDiv--col1_25 {
  width: 10.41%;
}

.defaultTableDiv--col2 {
  width: 16.6666666667%;
}

.defaultTableDiv--col3 {
  width: 25%;
}

.defaultTableDiv--col4 {
  width: 33.3333333333%;
}

.defaultTableDiv--col5 {
  width: 41.6666666667%;
}

.defaultTableDiv--col6 {
  width: 50%;
}

.defaultTableDiv--col7 {
  width: 58.3333333333%;
}

.defaultTableDiv--col8 {
  width: 66.6666666667%;
}

.defaultTableDiv--col9 {
  width: 75%;
}

.defaultTableDiv--col10 {
  width: 83.3333333333%;
}

.defaultTableDiv--col11 {
  width: 91.6666666667%;
}

.defaultTableDiv--col12 {
  width: 100%;
}

.defaultTableDiv-titles {
  background-color: transparent !important;
  text-transform: uppercase;
}

.defaultTableDiv-titles div {
  font-size: 15px;
  font-weight: bold;
  color: #686a78 !important;
}

@media (max-width: 767px) {
  .defaultTableDiv-row-column {
    text-align: center;
  }

  .defaultTableDiv--colmHide {
    display: none;
  }

  .defaultTableDiv--colm1 {
    width: 8.3333333333%;
  }

  .defaultTableDiv--colm2 {
    width: 16.6666666667%;
  }

  .defaultTableDiv--colm3 {
    width: 25%;
  }

  .defaultTableDiv--colm4 {
    width: 33.3333333333%;
  }

  .defaultTableDiv--colm5 {
    width: 41.6666666667%;
  }

  .defaultTableDiv--colm6 {
    width: 50%;
  }

  .defaultTableDiv--colm7 {
    width: 58.3333333333%;
  }

  .defaultTableDiv--colm8 {
    width: 66.6666666667%;
  }

  .defaultTableDiv--colm9 {
    width: 75%;
  }

  .defaultTableDiv--colm10 {
    width: 83.3333333333%;
  }

  .defaultTableDiv--colm11 {
    width: 91.6666666667%;
  }

  .defaultTableDiv--colm12 {
    width: 100%;
  }
}

@media (max-width: 959px) {
  .defaultTableDiv--collHide {
    display: none;
  }

  .defaultTableDiv--coll1 {
    width: 8.3333333333%;
  }

  .defaultTableDiv--coll2 {
    width: 16.6666666667%;
  }

  .defaultTableDiv--coll3 {
    width: 25%;
  }

  .defaultTableDiv--coll4 {
    width: 33.3333333333%;
  }

  .defaultTableDiv--coll5 {
    width: 41.6666666667%;
  }

  .defaultTableDiv--coll6 {
    width: 50%;
  }

  .defaultTableDiv--coll7 {
    width: 58.3333333333%;
  }

  .defaultTableDiv--coll8 {
    width: 66.6666666667%;
  }

  .defaultTableDiv--coll9 {
    width: 75%;
  }

  .defaultTableDiv--coll10 {
    width: 83.3333333333%;
  }

  .defaultTableDiv--coll11 {
    width: 91.6666666667%;
  }

  .defaultTableDiv--coll12 {
    width: 100%;
  }
}

.ant-radio-group {
  width: 100%;
}

.text-center {
  text-align: center;
  justify-content: center;
}

.text-right {
  text-align: right;
  justify-content: end;
}

.pageHeaderGeneral {
  background-color: #fff;
  padding-top: 15px;
}

.pageHeaderGeneral-Title {
  font-size: 30px !important;
  font-weight: 600 !important;
  color: #000 !important;
  opacity: 0.9;
}

.addNewLessonTabVideo-fileArea {
  display: flex;
  justify-content: space-around;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ant-custom-button-hoverless {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  min-height: 48px;
  border-radius: 35px;
  padding: 0 17px;
  border: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 10px;
  border-color: transparent !important;
  color: #fff;
  color: #fff;
  font-weight: 600;
  letter-spacing: normal;
}

.ant-custom-button-hoverless span {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: #fff;
}

.ant-custom-button-hoverless .ant-form-item {
  width: 100%;
}

.cursorPoint {
  cursor: pointer;
}

.ML__keyboard {
  z-index: 99999999999 !important;
}

.iiz {
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.iiz--drag .iiz__zoom-img--visible {
  cursor: -webkit-grab;
  cursor: grab;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
}

.iiz__img--hidden {
  visibility: hidden;
  opacity: 0;
}

.iiz__img--abs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
  -ms-touch-action: none;
  touch-action: none;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background: white;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: 0;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#222),
      to(#222)
    ),
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222));
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.saveButton {
  background-color: #a7e9aa;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 111px;
  min-height: 48px;
  color: #fff;
  margin: 0px auto;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.1px;
  cursor: pointer;
}

.disabledInput {
  pointer-events: none;
}

.clearfix {
  clear: both;
  display: block;
  width: 100%;
}
