@import "../../../../Assets/styles/fileSass/variables";
@import "../../../../Assets/styles/fileSass/media";
@import "../../../../Assets/styles/fileSass/extend";
.pageNotFound {
    padding: 20px;

    &-logo {
        padding: 0 10px;

        .icon {
            width: 163px;
            height: 43px;
        }
    }

    &-text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        &-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            &__title {
                font-size: 38px;
                font-weight: bold;
                color: #ee7ca8;
                margin-bottom: 30px;

                @media (max-width: 1023px) {
                    font-size: 28px;
                }

                @media (max-width: 767px) {
                    font-size: 26px;
                }

                @media (max-width: 479px) {
                    font-size: 24px;
                }
            }

            &-errCode {
                span {
                    font-size: 192px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    text-align: center;

                    @media (max-width: 1023px) {
                        font-size: 120px;
                    }

                    @media (max-width: 767px) {
                        font-size: 55px;
                    }

                    @media (max-width: 479px) {
                        font-size: 50px;
                    }
                }

                span:nth-child(1) {
                    color: #ffc700;
                }

                span:nth-child(2) {
                    color: #978cd3;
                }

                span:nth-child(3) {
                    color: #a4d574;
                }
            }

            &__button {
                width: 170px;
                height: 38px;
            }
        }
    }
}