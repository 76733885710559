@import "../../../../../Assets/styles/fileSass/variables";
@import "../../../../../Assets/styles/fileSass/media";
@import "../../../../../Assets/styles/fileSass/extend";
.footerTermsOfUse {
  background-color: #fafafb;
  height: 51px;
  @extend %displayCenter;
  padding: 0 20px;

  @include media1023 {
    padding: 0 10px;
  }
  @include media719 {
    text-align: center;
  }

  &-container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    opacity: 0.5;
    span {
      font-size: 14px;
      font-weight: 500;
      color: #5d5d6e;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
