@import "variables";
@import "media";
@import "extend";
.defaultButton {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-weight: 600;

  &-bg2c51fe {
    background-color: #2c51fe;
  }

  &-color2c51fe,
  &-color2c51fe:hover {
    color: #2c51fe;
  }

  &-colorfff,
  &-colorfff:hover {
    color: #fff;
  }

  &-borderRadius10 {
    border-radius: 10px;
  }

  &-borderRadius25 {
    border-radius: 25px;
  }

  &-border2c51fe {
    border: 1px solid #2c51fe;
  }
}

.defaultButton i,
.defaultButton svg {
  margin-left: 10px;
}
