@import "../../../../../Assets/styles/fileSass/variables";
@import "../../../../../Assets/styles/fileSass/media";
@import "../../../../../Assets/styles/fileSass/extend";
.layoutPublic {
  .containerSmall {
    padding: 0;

    @include media1200 {
      padding: 0 15px !important;
    }
  }

  &-header {
    height: 70px;
    padding: 0;
    @extend %displayBetweenCenter;

    &-logo {
      @extend %displayBetweenCenter;

      @include media767 {
        display: none;
      }

      &-name {
        font-size: 20px;
        color: #798faf;
        margin-left: 5px;
      }

      &-home {
        @include media767 {
          display: none;
        }

        .icon {
          width: 163px;
          height: 43px;
        }

        &-mobil {
          display: none;
          margin-left: 35px;

          @include media767 {
            display: block;
            margin-left: 0;
          }

          .icon {
            width: 106px;
            height: 27px;
          }
        }
      }
    }

    &-center {
      @extend %displayCenterCenter;

      @include media767 {
        display: none;
      }

      a {
        font-size: 16px;
        font-weight: 600;
        color: #686a78;
        margin-left: 16px;
        margin-top: 10px;
      }
    }

    &-right {
      @extend %displayEndCenter;

      @include media959 {
        width: 30%;
      }

      @include media767 {
        width: 70%;
      }

      a {
        width: 150px;
        height: 46px;
        margin-right: 10px;

        @include media959 {
          width: 45%;
          font-size: 12px;
        }
      }

      a:last-child {
        margin-right: 0;
      }
    }
  }
}

.publicBreadcrumb {
  position: absolute;
  top: 70px;
  width: 50%;

  @include media959 {
    top: 60px;
  }

  @include media767 {
    width: 100%;
    top: 70px;
  }

  h1 {
    font-size: 99px;
    font-weight: 900;
    margin-top: 50px;
    color: #fff;
    display: block;

    @include media1023 {
      font-size: 70px;
      margin-top: 10px;
    }

    @include media959 {
      font-size: 30px;
      margin-top: 10px;
    }
  }

  &-menu {
    display: flex;
  }

  &-menu a,
  &-menu span,
  &-separator {
    display: flex;
    font-size: 18px;
    color: #fff !important;
  }

  &-separator svg {
    fill: #fff;
    position: relative;
    top: -2px;
  }
}
